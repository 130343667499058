import { isToday } from 'date-fns';

import { GetArena } from '~/apis';
import { defaultTimeOptions } from '~/components/input';
import { getDayNameFromDate } from '~/utils/date';
import { differenceInMinutesForMilitaryFormat } from '~/utils/time';

export function getArenaWorkingHours(arena: GetArena.Arena, date: string) {
  const workingHours = arena.availability.hours.find(({ day }) => day === getDayNameFromDate(date));
  if (!workingHours) {
    throw new Error(`Missing working hours for date ${date}`);
  }

  return workingHours;
}

export function getMinutesToClosingTime(arena: GetArena.Arena, date: string, currentHours: number) {
  const { start: openingHours, end: closingHours } = getArenaWorkingHours(arena, date);

  const openingOrCurrentHours = isToday(new Date(date)) ? currentHours : openingHours;

  return differenceInMinutesForMilitaryFormat(openingOrCurrentHours, closingHours);
}

export function getTimeOptions(
  arena: GetArena.Arena,
  date: string,
  type: 'end' | 'start',
  currentHours: number,
  startTime?: string,
) {
  const { start: arenaOpenTime, end: arenaCloseTime } = getArenaWorkingHours(arena, date);
  let timeOptions = defaultTimeOptions
    .filter((option) => parseInt(option.value) >= arenaOpenTime)
    .filter((option) => parseInt(option.value) <= arenaCloseTime);

  // booking can start no later than 30 minutes before closing time
  if (type === 'start') {
    timeOptions = timeOptions.filter(
      (option) => differenceInMinutesForMilitaryFormat(arenaCloseTime.toString(), option.value) >= 30,
    );
  }

  // end time is at least 30 minutes after the start time
  if (type === 'end' && startTime !== undefined) {
    timeOptions = timeOptions.filter((option) => differenceInMinutesForMilitaryFormat(option.value, startTime) >= 30);
  }

  // first start time option must be the first available rounded time (00, 15, 30, 45) after the current time
  if (isToday(new Date(date))) {
    timeOptions = timeOptions.filter((option) => {
      return differenceInMinutesForMilitaryFormat(option.value, currentHours.toString()) > 0;
    });
  }

  return timeOptions;
}
