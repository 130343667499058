import { last } from 'lodash';

import { TimeOption } from '~/components/input';

export interface TypeOfTimeOptions {
  label: string;
  value: string;
}

export function defaultEndTime(timeOptions: TypeOfTimeOptions[]) {
  // take 2 hrs range if it's possible
  // else take the last offered time
  return timeOptions[6]?.value ?? last(timeOptions)?.value;
}

export function defaultTimeSlot(type: 'start' | 'end', startTime: any, timeOptions: TimeOption[]) {
  if (type !== 'end' || !startTime) {
    return timeOptions[0]?.value;
  }

  return defaultEndTime(timeOptions);
}
