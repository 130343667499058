import { StoreAppBadges } from '~/components/shared';
import { Box, FlexLayout, Image, Only, Sanitize, Text } from '~/ui';

type MobilePromotionVariant = 'Home page' | 'Booking Confirmation page';

interface MobilePromotionWidgetProps {
  data: {
    body: string;
    title: string;
    image: {
      url: string;
    };
    variant: MobilePromotionVariant;
  };
}

export const MobilePromotionWidget: React.FC<MobilePromotionWidgetProps> = ({ data }) => {
  const { title, body, image, variant } = data;

  let contentSpace: number | number[] = [0, 6, 9];
  let contentPx: number | number[] = [4, 5, 9];
  let contentVariant = 'display-paragraph-l';

  if (variant === 'Booking Confirmation page') {
    contentPx = 0;
    contentSpace = 7;
    contentVariant = 'display-paragraph-m';
  }

  return (
    <Box pt={[0, 5, 6]} sx={{ position: 'relative', overflow: 'hidden' }}>
      <FlexLayout bg="black" px={contentPx} space={contentSpace}>
        <Only for="tabletAndDesktop">
          <Box sx={{ minWidth: '201px' }}>
            <Image src={image?.url} sx={{ position: 'absolute', top: 0 }} />
          </Box>
        </Only>
        <FlexLayout
          flexDirection="column"
          py={[4, 6, '40px']}
          space={5}
          sx={{
            maxWidth: ['100%', '100%', '620px'],
          }}
        >
          <Text variant="title-l">{title}</Text>
          <Sanitize html={body} sx={{ p: { margin: 0, variant: `text.${contentVariant}` } }} />
          <StoreAppBadges />
        </FlexLayout>
      </FlexLayout>
    </Box>
  );
};
