import { membershipTiersQuery, pricesQuery } from '~/hooks/api';

export const useBelongLegendsPricingInfo = () => {
  const response = membershipTiersQuery.use({});

  const data = response.data?.items.find((item) => item.membershipName.toLowerCase().includes('legend'));

  return { ...response, data };
};

export const useBelongLegendsArenaPrice = (arenaId: string | undefined) => {
  const { data: pricingInfo } = useBelongLegendsPricingInfo();
  const sku = pricingInfo?.sku;
  const pricesArgs = arenaId && sku && { params: { arenaId, sku } };

  return pricesQuery.use(pricesArgs);
};
