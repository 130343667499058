import { Box, FlexLayout, Image, Only, Text } from '~/ui';

interface HeroData {
  title: string;
  is_title_visible: boolean;
  image?: { url: string };
  has_brand_logo?: boolean;
  title_image?: { url: string };
  shrink_background_image: boolean;
}

interface HeroProps {
  data: HeroData;
}

const legendsLogoPath = '/assets/images/legends-logo.svg';

const HeroHeading: React.FC<{ title: string; image: string | undefined }> = ({ title, image }) => (
  <FlexLayout alignItems="center" space={3}>
    {image && <Image src={image} sx={{ height: ['35px', '50px'], width: ['35px', '50px'] }} />}
    <Text as="h1" variant="display-heading-l">
      {title}
    </Text>
  </FlexLayout>
);

export const Hero: React.FC<HeroProps> = ({ data }) => {
  const {
    title,
    image,
    has_brand_logo: hasBrandLogo,
    title_image,
    is_title_visible: isTitleVisible,
    shrink_background_image: shrinkBackgroundImage,
  } = data;

  return (
    <Box mb={[7, 8]}>
      <Box
        as="header"
        sx={{
          minHeight: shrinkBackgroundImage ? 'unset' : '400px',
          backgroundImage: image?.url && !shrinkBackgroundImage ? `url('${image.url}')` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: shrinkBackgroundImage ? 'unset' : '50%',
          position: shrinkBackgroundImage ? 'relative' : 'unset',
        }}
      >
        {shrinkBackgroundImage && image?.url && <Image src={image.url} />}
        <FlexLayout
          flexDirection="column"
          justifyContent="flex-end"
          pb={isTitleVisible ? 6 : undefined}
          pt={isTitleVisible ? 7 : undefined}
          px={[4, 5]}
          space={6}
          sx={{
            minHeight: 'inherit',
            maxWidth: ['initial', '640px'],
            position: shrinkBackgroundImage ? 'absolute' : 'unset',
            bottom: shrinkBackgroundImage ? 0 : undefined,
          }}
        >
          {hasBrandLogo && <Image src={legendsLogoPath} sx={{ alignSelf: 'flex-start', height: '234px' }} />}
          {isTitleVisible && (
            <Only for="tabletAndDesktop">
              <HeroHeading image={title_image?.url} title={title} />
            </Only>
          )}
        </FlexLayout>
      </Box>
      {isTitleVisible && (
        <Only for="mobile">
          <Box bg="black" p={4}>
            <HeroHeading image={title_image?.url} title={title} />
          </Box>
        </Only>
      )}
    </Box>
  );
};
