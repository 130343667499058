import { GetHomePage } from '~/apis';

import { TextImageCard } from './TextImageCard';

interface CovidBannerProps {
  data: GetHomePage.CovidBanner & { body: string; active: boolean };
}

export const CovidBanner: React.FC<CovidBannerProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  if (!data.active) {
    return null;
  }

  return <TextImageCard data={{ ...data, content: data.body }} />;
};
