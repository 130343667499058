import { GetArena } from '~/apis';
import { useCurrentHours, useTodayDate } from '~/hooks/date';
import { getTodaysName } from '~/utils/date';
import { getTimeDifferenceObjectFromMilitary } from '~/utils/time';

export function getArenaOpenStatus(arena: GetArena.Arena) {
  const { hours, tz } = arena.availability;
  const { allowedBookingDate, allowBooking } = arena.status;

  const today = useTodayDate();
  const isOpeningInThePast = new Date(allowedBookingDate) < today;

  if (allowBooking && !isOpeningInThePast) {
    return undefined;
  }

  if (!isOpeningInThePast) {
    return 'closed now';
  }

  const currentHours = useCurrentHours(tz);
  const todaysAvailability = hours.find(({ day }) => day === getTodaysName());

  if (!todaysAvailability) {
    return 'unknown time';
  }

  const { start: arenaStartTime, end: arenaEndTime } = todaysAvailability;
  const isMorning = currentHours < 1200;

  const timeDiff = getTimeDifferenceObjectFromMilitary({
    start: currentHours,
    end: isMorning ? arenaStartTime : arenaEndTime,
  });

  if (
    (timeDiff.hours && timeDiff.hours === 1 && !timeDiff.minutes) ||
    (!timeDiff.hours && timeDiff.minutes && timeDiff.minutes > 0 && timeDiff.minutes <= 59)
  ) {
    return isMorning ? 'opening soon' : 'closing soon';
  }

  if (currentHours >= arenaStartTime && currentHours < arenaEndTime) {
    return 'open now';
  }

  return 'closed now';
}
