import { useRouter } from 'next/router';
import { useState } from 'react';

import { AnchorButton } from '~/components/input';
import { TextIconBox } from '~/components/shared';
import { FlexLayout, GridLayout, Image, MaxWidthWrapper, Only, Text, theme, useScreenType } from '~/ui';

import { Filters } from './Filters';
import { FiltersNote } from './FiltersNote';
import { filterGames } from './utils';

export type Image = {
  id?: string;
  uid?: string;
  isGuestAccountAvailable?: boolean;
  url: string;
  title?: string;
};

interface BelongGamesWidgetProps {
  data: {
    active: boolean;
    title: string;
    games: {
      images: Array<Image> | undefined;
    };
    cta?: {
      title: string;
      href: string;
    };
  };
  showDetails?: boolean;
}

export const BelongGamesWidget: React.FC<BelongGamesWidgetProps> = ({ data, showDetails = false }) => {
  const { isMobile } = useScreenType();
  const { pathname } = useRouter();
  const [filter, setFilter] = useState('all');
  const isGamesPage = pathname === '/games';

  if (!data || !data.active) {
    return null;
  }

  const {
    cta,
    title,
    games: { images },
  } = data;

  const filteredGames = !isGamesPage && isMobile ? images?.slice(0, 6) : filterGames(images, filter);

  return (
    <MaxWidthWrapper>
      <GridLayout gap={5} sx={{ maxWidth: '100%' }}>
        <FlexLayout flexDirection={['column', 'row']} justifyContent="space-between" space={[5, 0]}>
          <FlexLayout flexDirection="column" space={3}>
            <Text
              variant={
                isMobile
                  ? isGamesPage
                    ? 'title-m'
                    : 'display-heading-m'
                  : isGamesPage
                  ? 'display-heading-m'
                  : 'display-heading-l'
              }
            >
              {title}
            </Text>
            <Only for="tabletAndDesktop">
              <FiltersNote value={filter} />
            </Only>
          </FlexLayout>
          {showDetails && <Filters value={filter} onChange={(value) => setFilter(value)} />}
          <Only for="mobile">
            <FiltersNote value={filter} />
          </Only>
        </FlexLayout>
        {images && (
          <GridLayout gap={6} justifyItems="center">
            <GridLayout
              gap={[4, 4, 6]}
              gridTemplateColumns={
                isGamesPage
                  ? ['repeat(2, 1fr)', 'repeat(4, 1fr)', 'repeat(6, 1fr)']
                  : ['repeat(12, 1fr)', 'repeat(12, 1fr)', 'repeat(6, 1fr)']
              }
              pb={4}
              sx={{ overflowX: 'auto', width: '100%' }}
            >
              {filteredGames &&
                filteredGames.map(({ url, title, id, uid, isGuestAccountAvailable }) => (
                  <FlexLayout
                    flexDirection="column"
                    key={uid ?? id}
                    space={3}
                    sx={{
                      border: showDetails ? `1.5px solid ${theme.colors['white-alpha-10']}` : 'none',
                      padding: showDetails ? 2 : 0,
                      minWidth: showDetails ? undefined : ['144px', '192px'],
                    }}
                  >
                    <Image
                      src={url}
                      sx={{
                        maxWidth: showDetails ? '176px' : '100%',
                        maxHeight: showDetails ? '264px' : 'auto',
                        objectFit: 'cover',
                      }}
                    />
                    {showDetails && title && (
                      <TextIconBox
                        icon={isGuestAccountAvailable ? 'access' : 'free'}
                        iconPosition="right"
                        justifyContent="space-between"
                        space={2}
                        sx={{ text: { maxWidth: ['120px', '128px', '144px'] } }}
                        text={title}
                        variant="text-m"
                      />
                    )}
                  </FlexLayout>
                ))}
            </GridLayout>
            {cta && <AnchorButton href={cta.href} text={cta.title} variant="secondary" />}
          </GridLayout>
        )}
      </GridLayout>
    </MaxWidthWrapper>
  );
};
