import flatten from 'lodash/flatten';

import { existingPaymentMethodsQuery } from '~/hooks/api';
import { theme } from '~/ui';
import { getCardTypeIconName } from '~/utils/payment';

export const useExistingPaymentMethods = (props = { skip: false }) => {
  const { skip } = props;

  const { data, isLoading, error } = existingPaymentMethodsQuery.use(skip ? undefined : {});
  if (!data) {
    return { data, isLoading, error };
  }
  const result = data?.payers.map((payer) => [
    ...payer.cards.map((card) => ({
      label: `${card.cardBrand?.replace('_', ' ') ?? 'Credit'} card ending in •••• ${card.lastFour}`,
      value: `${payer.customerId}|${card.id}`,
      iconLeft: getCardTypeIconName(card.cardBrand),
      customerId: payer.customerId,
      cardId: card.id,
      expMonth: card.expMonth,
      expYear: card.expYear,
      lastFour: card.lastFour,
      cardBrand: card.cardBrand,
    })),
    ...payer.giftCards.map((giftCard) => ({
      label: `${giftCard.ganSource?.replace('_', ' ') ?? 'Square'} gift card ending in •••• ${giftCard.gan.slice(-4)}`,
      value: `${payer.customerId}|${giftCard.id}`,
      iconLeft: 'gift' as theme.Icon,
      customerId: payer.customerId,
      cardId: giftCard.id,
      lastFour: giftCard.gan.slice(-4),
      gan: giftCard.gan,
      ganSource: giftCard.ganSource,
      createdAt: giftCard.createdAt,
      balanceMoney: giftCard.balanceMoney,
    })),
  ]);

  return { data: flatten(result), isLoading: false, error: undefined };
};
