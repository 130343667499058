import { DateTime } from 'luxon';
import { createContext, useContext, useEffect } from 'react';

import { GetArena } from '~/apis';

const TimezoneContext = createContext<string | undefined>(undefined);

interface TimezoneContextProviderProps {
  availability: GetArena.Availability;
  children: any;
}

export const TimezoneContextProvider: React.FC<TimezoneContextProviderProps> = ({ availability, children }) => {
  const { timezone, tz } = availability;

  useEffect(() => {
    if (!tz) {
      console.warn('tz is missing!');
    }
  }, [tz]);

  return <TimezoneContext.Provider value={tz ?? timezone}>{children}</TimezoneContext.Provider>;
};

export function useTimezone(defaultTimezone?: string) {
  const timezone = useContext(TimezoneContext) ?? defaultTimezone;
  if (!timezone) {
    throw new Error('Missing timezone in React context, use TimezoneContextProvider');
  }

  function getHours(time: string) {
    return DateTime.fromISO(time, { zone: timezone }).toFormat('h:mm a');
  }

  return {
    timezone,
    getHours,
  };
}
