import { TimeSelect } from '~/components/input';
import { withFormIntegration } from '~/hocs';
import { arenaQuery } from '~/hooks/api';
import { withFieldLabels } from '~/ui';

import { ArenaWorkingHoursSelector } from './ArenaWorkingHoursSelector';

const TimeSelectWithFieldLabels = withFieldLabels(TimeSelect);
const FormTimeSelect = withFormIntegration(TimeSelectWithFieldLabels);

interface ArenaWorkingHoursSelectorContainerProps {
  type: 'start' | 'end';
  arenaSlug?: string;
  name: string;
  label: string;
  defaultValue?: string;
  isDisabled?: boolean;
  'data-testid'?: string;
}

export const ArenaWorkingHoursSelectorContainer: React.FC<ArenaWorkingHoursSelectorContainerProps> = ({
  arenaSlug,
  defaultValue,
  type,
  name,
  label,
  isDisabled,
  'data-testid': dataTestId,
}) => {
  const { data: arena } = arenaQuery.use(arenaSlug && { slug: arenaSlug });

  if (!arena) {
    return (
      <FormTimeSelect
        data-testid={dataTestId}
        defaultValue={defaultValue}
        isDisabled
        label={label}
        name={name}
        timeOptions={[]}
      />
    );
  }

  return (
    <ArenaWorkingHoursSelector
      arena={arena}
      data-testid={dataTestId}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      label={label}
      name={name}
      type={type}
    />
  );
};
