import { useState } from 'react';

import { GetArenas } from '~/apis';
import { AnchorButton } from '~/components/input';
import { ArenaWidgetNotice, OptionalLink, TextIconBox } from '~/components/shared';
import { ArenaLogo } from '~/components/shared/ArenaLogo';
import { FlexLayout, Text } from '~/ui';
import { convertApiArenaToUIArena } from '~/utils/arena';

export const ArenaWidget: React.FC<{ arena: GetArenas.Arena }> = ({ arena }) => {
  const {
    statusText,
    heroImageUrl,
    logoImageUrl,
    name,
    address,
    notice,
    phone,
    state,
    city,
    href,
    isBookingAllowed,
    slug,
    allowDetail,
  } = convertApiArenaToUIArena(arena);

  const [isBookNowClicked, setIsBookNowClicked] = useState(false);

  return (
    <FlexLayout bg="black" flexDirection="column" p={5} space={4} sx={{ position: 'relative', overflow: 'hidden' }}>
      {statusText && (
        <FlexLayout
          alignItems="center"
          bg="white"
          justifyContent="center"
          sx={{
            height: '34px',
            position: 'absolute',
            transform: 'rotate(-45deg)',
            width: '250px',
            left: '-60px',
            top: '45px',
          }}
        >
          <Text color="black" variant="compact-text-l">
            {statusText}
          </Text>
        </FlexLayout>
      )}
      <ArenaLogo heroImageUrl={heroImageUrl} href={allowDetail && href} logoImageUrl={logoImageUrl} />
      <FlexLayout flexDirection="column" flexGrow={1} space={4}>
        <FlexLayout flexDirection="column">
          <OptionalLink href={allowDetail ? href : undefined}>
            <Text variant="title-l">{name}</Text>
          </OptionalLink>
          <Text color="white-alpha-75" variant="text-m">
            {state}
          </Text>
        </FlexLayout>
        <FlexLayout flexDirection="column" flexGrow={1} space={3}>
          {allowDetail && (
            <TextIconBox
              alignItems="flex-start"
              icon="locationPin"
              space={3}
              subtext={city}
              text={address}
              variant="text-m"
            />
          )}
          {allowDetail && <TextIconBox icon="phone" space={3} text={phone} variant="text-m" />}
          <ArenaWidgetNotice allowDetail={allowDetail} notice={notice} />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout mt={2} onClick={() => allowDetail && setIsBookNowClicked(true)}>
        <AnchorButton
          href={isBookingAllowed ? `/arenas/${slug}/book` : href}
          isDisabled={!allowDetail}
          isFullWidth
          isLoading={isBookNowClicked}
          mt={2}
          text={isBookingAllowed ? 'Book now' : 'Find out more'}
          variant={isBookingAllowed ? 'primary' : 'secondary'}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
