import { CreateMembershipArgs, GetMemberships } from '~/apis';
import {
  arenaQuery,
  createMembershipMutation,
  membershipCancelReasonsQuery,
  membershipsQuery,
  useAuthenticatedUser,
} from '~/hooks/api';
import { useExistingPaymentMethods } from '~/hooks/payments';
import { useBelongLegendsPricingInfo } from '~/hooks/prices';
import { getFeatureFlags } from '~/services/featureFlags';
import { isPastDate } from '~/utils/date';
import { formatPriceObject, isSameCard } from '~/utils/payment';

function getLatestMembership(data: GetMemberships.RootObject | undefined) {
  if (!data) {
    return undefined;
  }

  return data.items.sort((a, b) => {
    return new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf();
  })[0];
}

function transformMembershipData(data: GetMemberships.Item | undefined) {
  if (!data) {
    return undefined;
  }

  const {
    amount,
    autoRenew,
    arenaId,
    billingDate,
    cancelReasonId,
    endDate,
    id,
    paymentCard: { cardId },
    paymentCardDetails,
    startDate,
    status,
  } = data;
  const amountInDollars = formatPriceObject({ currency: 'USD', amount }, { isMinorUnit: true });

  return {
    autoRenew,
    arenaId,
    cancelReasonId,
    endDate,
    id,
    price: `${amountInDollars} / per month`,
    memberSince: startDate,
    membershipId: id,
    nextChargeDate: billingDate,
    paymentMethod: {
      ...paymentCardDetails,
      cardId,
    },
    status,
  };
}

export function useMembershipInfo() {
  const { user } = useAuthenticatedUser();
  const fullUserId = user?.sub;
  const userId = fullUserId?.split('|')[1]; // unmarshall userId

  const { data, isLoading, error } = membershipsQuery.use(userId && { params: { userId } });
  const { data: payments, isLoading: isLoadingPaymentMethods } = useExistingPaymentMethods({ skip: !data });

  const latestMembership = getLatestMembership(data);
  const transformedData = transformMembershipData(latestMembership);

  const isMembershipPaymentExistsAsAccountPayment = !!(
    transformedData && payments?.find((payment) => isSameCard(payment, transformedData.paymentMethod))
  );

  return {
    isLoading: isLoading || isLoadingPaymentMethods,
    isMembershipPaymentExistsAsAccountPayment,
    error,
    data: transformedData,
  };
}

export function useMembershipStatus() {
  const { isLoading, data } = useMembershipInfo();

  const isMembershipCanceledButNotExpired = data?.status === 'CANCELED' && !isPastDate(data?.endDate);
  const hasMembership = data?.status === 'ACTIVE' || isMembershipCanceledButNotExpired;

  return {
    hasMembership,
    isLoading,
    isMembershipCanceledButNotExpired,
    status: data?.status,
  };
}

export function useMembershipCancellationReasons() {
  const { data, isLoading, error } = membershipCancelReasonsQuery.use({});

  return {
    data: data ? data.items : [],
    isLoading,
    error,
  };
}

export function useMembershipMarketingInfo() {
  const { membership } = getFeatureFlags();
  const { hasMembership, isLoading, status } = useMembershipStatus();

  const shouldDisplayMarketingWidget =
    membership &&
    !hasMembership &&
    status !== 'CANCELED' &&
    status !== 'DEACTIVATED' &&
    status !== 'PENDING_DEACTIVATION';

  return { shouldDisplayMarketingWidget, isLoading };
}

type CreateMembershipHandlerArgs =
  | { arenaId: string; cardNonce: string; saveCard: boolean }
  | { arenaId: string; cardId: string; payerId: string };

export function useCreateMembershipMutation() {
  const { mutateAsync: createMembershipApi, ...rest } = createMembershipMutation.use();
  const { data: tier } = useBelongLegendsPricingInfo();
  const { user } = useAuthenticatedUser();

  async function createMembership(args: CreateMembershipHandlerArgs) {
    if (!tier || !user) {
      return;
    }

    const membershipTierId = tier.id;
    const { email: emailAddress, sub: userId } = user;
    const { arenaId } = args;

    let payment: CreateMembershipArgs.Payment;
    if ('cardNonce' in args) {
      // using a new credit card
      const { cardNonce, saveCard } = args;
      payment = { cardNonce, saveCard: saveCard ? true : undefined, payer: { emailAddress } };
    } else {
      // using an existing credit card
      const { payerId, cardId } = args;
      payment = { payerId, cardId };
    }

    await createMembershipApi({
      membershipTierId,
      arenaId,
      payment,
      termsAccepted: true,
      userId,
    });
  }

  return { createMembership, ...rest };
}

export function useMembershipArena() {
  const membership = useMembershipInfo();
  const arenaId = membership.data?.arenaId;

  const arena = arenaQuery.use(arenaId && { slug: arenaId });

  return {
    data: arena.data,
    isLoading: membership.isLoading || arena.isLoading,
    error: membership.error || arena.error,
  };
}
