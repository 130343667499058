import { useRef } from 'react';

import { useTimezone } from '~/hooks/timezone';
import { convertDateToMilitary } from '~/utils/time';

export function useTodayDate() {
  const todayRef = useRef<Date>();
  if (!todayRef.current) {
    todayRef.current = new Date();
  }

  if (!todayRef.current) {
    throw new Error("Today ref wasn't initialized");
  }

  return todayRef.current;
}

export function useCurrentHours(defaultTimeZone = 'utc') {
  const { timezone } = useTimezone(defaultTimeZone);
  return parseInt(convertDateToMilitary(useTodayDate(), timezone));
}
