import Head from 'next/head';

import { GetAboutPage } from '~/apis';
import { useCurrentUrl } from '~/hooks/routing';

interface SeoProps {
  data: GetAboutPage.Seo;
}

export const Seo: React.FC<SeoProps> = ({ data }) => {
  const { meta_description: description, meta_title: title, image, type = 'website' } = data;
  const currentUrl = useCurrentUrl();

  return (
    <Head>
      <title>{title}</title>
      <meta content={description} key="description" name="description" />
      {/* Facebook Meta Tags */}
      <meta content={currentUrl} key="og:url" property="og:url" />
      <meta content={type} key="og:type" property="og:type" />
      <meta content={title} key="og:title" property="og:title" />
      <meta content={description} key="og:description" property="og:description" />
      <meta content={image.url} key="og:image" property="og:image" />
      <meta content={image.url} key="og:image:secure_url" property="og:image:secure_url" />
      <meta content="Belong" key="og:site_name" property="og:site_name" />
      {/* Twitter Meta Tags */}
      <meta content="summary_large_image" key="twitter:card" name="twitter:card" />
      <meta content={title} key="twitter:title" name="twitter:title" />
      <meta content={description} key="twitter:description" name="twitter:description" />
      <meta content={image.url} key="twitter:image" name="twitter:image" />
      <meta content="@BelongArenas" key="twitter:site" name="twitter:site" />
    </Head>
  );
};
