import { withFormIntegration } from '~/hocs';
import { arenasQuery } from '~/hooks/api';
import { SingleSelectWithFieldLabels } from '~/ui';

interface ArenaSelectProps {
  name: string;
}

const FormSingleSelect = withFormIntegration(SingleSelectWithFieldLabels);

export const ArenaSelect: React.FC<ArenaSelectProps> = ({ name }) => {
  const { data: arenas } = arenasQuery.use({});

  const arenaOptions = arenas?.items
    .filter((arena) => arena.status.allowBooking)
    .map(({ name, slug }) => ({ label: name, value: slug }));

  const defaultSlug = arenaOptions?.[0]?.value;

  return (
    <FormSingleSelect
      data-testid="pick-arena"
      defaultValue={defaultSlug}
      label="Arena"
      name={name}
      options={arenaOptions ?? []}
      placeholder="Select arena"
    />
  );
};
