import take from 'lodash/take';

import { CenteredLoadingSpinner, NewsItemCard } from '~/components/shared';
import { featuredNewsItemsQuery } from '~/hooks/api';
import { FlexLayout, GridLayout, Text, useScreenType } from '~/ui';

interface WhatsNewProps {
  data: {
    active: boolean;
  };
}

export const WhatsNew: React.FC<WhatsNewProps> = ({ data }) => {
  const { isTablet } = useScreenType();

  // Valid before the hook since "data" is baked-in during the build.
  if (!data?.active) {
    return null;
  }

  const { data: news } = featuredNewsItemsQuery.use({});

  if (!news) {
    return <CenteredLoadingSpinner />;
  }

  const featuredNews = news[0][0].news_items;
  const filteredNews = isTablet ? take(featuredNews, 2) : take(featuredNews, 3);

  return (
    <FlexLayout flexDirection="column" space={5}>
      <Text variant="display-heading-l">What’s new</Text>
      <GridLayout gap={6} gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
        {filteredNews.map((entry) => (
          <NewsItemCard entry={entry} key={entry.uid} />
        ))}
      </GridLayout>
    </FlexLayout>
  );
};
