import { GetArena } from '~/apis/arenas/getArena';
import { Dot } from '~/components/shared';
import { Box, FlexLayout, GridLayout, Text } from '~/ui';
import { Color } from '~/ui/theme';
import { capitalizeWordsWithChar } from '~/utils/string';

import { QuickBookForm } from './QuickBookForm';
import { getArenaOpenStatus } from './utils';

interface QuickBookBarProps {
  arena?: GetArena.Arena;
  data?: {
    active: boolean;
    display_time_selectors: boolean;
  };
}

const openStatusColorsMap: Partial<Record<string, Color>> = {
  'opening soon': 'yellow-500',
  'closing soon': 'yellow-500',
  'open now': 'green-500',
  'closed now': 'red-500',
  'unknown time': 'red-500',
};

export const QuickBookBar: React.FC<QuickBookBarProps> = ({ arena, data }) => {
  if (data && !data.active) {
    return null;
  }

  // Home Page
  if (!arena) {
    return (
      <Box bg="black" mt="-138px" p={[5, 6]}>
        <QuickBookForm displayTimeSelectors={data?.display_time_selectors} />
      </Box>
    );
  }

  // Arena Page
  const {
    location,
    name,
    status: { allowBooking },
  } = arena;

  if (!allowBooking) {
    return (
      <GridLayout
        gap={[5, 5, 1]}
        gridTemplateColumns={['1fr', '1fr', '1fr 2fr']}
        mt={25}
        pb={[5, 6, 5]}
        pt={[3, 3, 5]}
        px={[5, 6, 5]}
      />
    );
  }

  const arenaOpenStatus = getArenaOpenStatus(arena);

  return (
    <GridLayout
      bg="black"
      gap={[5, 5, 1]}
      gridTemplateColumns={['1fr', '1fr', '1fr 2fr']}
      pb={[5, 6, 5]}
      pt={[3, 3, 5]}
      px={[5, 6, 5]}
      sx={{ borderTop: (theme) => `4px solid ${theme.colors.brandPrimaryColor}` }}
    >
      <FlexLayout flexDirection="column" pr={[0, 0, 6]} space={1}>
        <Text as="h1" variant="display-heading-m">
          {name}
        </Text>
        {arenaOpenStatus && (
          <FlexLayout alignItems="center" flexDirection="row" space={2}>
            <Dot color={openStatusColorsMap[arenaOpenStatus]} size="8px" />
            <Text color="white-alpha-75" variant="display-paragraph-m">
              {capitalizeWordsWithChar(arenaOpenStatus)} in {location.city}, {location.state}
            </Text>
          </FlexLayout>
        )}
      </FlexLayout>
      <QuickBookForm displayTimeSelectors={data?.display_time_selectors} preselectedArena={arena} />
    </GridLayout>
  );
};
