import { TextIconBox } from '~/components/shared';
import { FlexLayout, Text } from '~/ui';

export const FiltersNote = ({ value }: { value: string }) => {
  if (value === 'free-to-play') {
    return (
      <FlexLayout flexDirection={['column', 'column', 'row']} space={2}>
        <TextIconBox color="white" icon="free" space={2} text="Free to play" variant="text-m" />
        <Text color="white-alpha-75">
          These titles do not require purchase to play and are available to anyone with a personal account.
        </Text>
      </FlexLayout>
    );
  }

  if (value === 'guest-access') {
    return (
      <FlexLayout flexDirection={['column', 'column', 'row']} space={2}>
        <TextIconBox color="white" icon="access" space={2} text="Guest Access" variant="text-m" />
        <Text color="white-alpha-75">
          These titles have accounts provided by Belong. You do not need your own account to play these games.
        </Text>
      </FlexLayout>
    );
  }

  return null;
};
