import { GetMembershipsPage } from '~/apis';
import { Box, Divider, FlexLayout, Image, Only, Text } from '~/ui';

interface ListImageCard {
  data: GetMembershipsPage.ExtraSection & { has_image_dark_overlay?: boolean };
}

export const ListImageCard: React.FC<ListImageCard> = ({ data }) => {
  const {
    title,
    items,
    image: { url: imageUrl },
    has_image_dark_overlay: hasImageDarkOverlay,
  } = data;

  return (
    <FlexLayout flexDirection="column" space={[7, 8]} sx={{ position: 'relative', px: [4, 4, 0] }}>
      <Text as="h2" variant="display-heading-l">
        {title}
      </Text>
      <Box mx={[-4, 'initial']} sx={{ position: 'relative' }}>
        <Only for="desktop">
          <Image
            src={imageUrl}
            sx={{
              maxWidth: '40%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'left',
              position: 'absolute',
              right: 0,
              zIndex: hasImageDarkOverlay ? 0 : 1,
            }}
          />
        </Only>
        <Box
          p={5}
          sx={{
            background: [
              'black',
              'black',
              hasImageDarkOverlay ? 'linear-gradient(86.4deg,black 60%,transparent 80%)' : 'black',
            ],
            position: 'relative',
            width: '100%',
          }}
        >
          <FlexLayout flexDirection="column" space={[8, 5]} sx={{ maxWidth: ['initial', 'initial', '60%'] }}>
            {items.map((item) => (
              <FlexLayout flexDirection={['column', 'row']} key={item._metadata.uid} space={4}>
                <FlexLayout alignItems="center" space={4} sx={{ minWidth: '218px', height: 'max-content' }}>
                  <Text variant="compact-text-xl">{item.name}</Text>
                  <Divider />
                </FlexLayout>
                <Text variant="display-paragraph-l">{item.description}</Text>
              </FlexLayout>
            ))}
          </FlexLayout>
        </Box>
      </Box>
    </FlexLayout>
  );
};
