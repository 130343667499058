import { AnchorButton } from '~/components/input';
import { Link } from '~/components/shared';
import { FlexLayout, GridLayout, Image, MaxWidthWrapper, Text, useScreenType } from '~/ui';

type Image = {
  shop_url: {
    href: string;
  };
  image: {
    uid: string;
    url: string;
  };
};

interface BelongShoppingWidgetProps {
  data: {
    active: boolean;
    title: string;
    items: {
      images: Array<Image> | undefined;
    };
    cta?: {
      title: string;
      href: string;
    };
  };
}

export const BelongShoppingWidget: React.FC<BelongShoppingWidgetProps> = ({ data }) => {
  const { isMobile } = useScreenType();

  if (!data || !data.active) {
    return null;
  }

  const { title, items, cta } = data;

  return (
    <MaxWidthWrapper>
      <GridLayout gap={5} sx={{ maxWidth: '100%' }}>
        <Text variant={isMobile ? 'display-heading-m' : 'display-heading-l'}>{title}</Text>
        {items.images && (
          <GridLayout gap={6} justifyItems="center">
            <GridLayout
              gap={[4, 4, 6]}
              gridTemplateColumns={['repeat(12, 1fr)', 'repeat(12, 1fr)', 'repeat(6, 1fr)']}
              pb={4}
              sx={{ overflowX: 'auto', width: '100%' }}
            >
              {items.images.map(({ image, shop_url }) => (
                <FlexLayout flexDirection="column" key={image.uid} space={3} sx={{ minWidth: ['144px', '192px'] }}>
                  <Link href={shop_url.href} sx={{ pointerEvents: shop_url.href ? 'auto' : 'none' }}>
                    <Image
                      src={image.url}
                      sx={{
                        maxWidth: '100%',
                        maxHeight: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  </Link>
                </FlexLayout>
              ))}
            </GridLayout>
            {cta?.title && <AnchorButton href={cta.href} text={cta.title} variant="secondary" />}
          </GridLayout>
        )}
      </GridLayout>
    </MaxWidthWrapper>
  );
};
