import { GetHomePage } from '~/apis';
import { Box, FlexLayout, Sanitize, useScreenType } from '~/ui';

interface HomeHeroProps {
  data: GetHomePage.Hero;
}

export const HomeHero: React.FC<HomeHeroProps> = ({ data }) => {
  const { isMobile } = useScreenType();
  const { poster_image: posterImage, video, header } = data;

  return (
    <Box sx={{ height: '70vh', position: 'relative' }}>
      <video
        autoPlay
        controls={false}
        height="100%"
        loop
        muted
        playsInline
        poster={posterImage.url}
        style={{ objectFit: 'cover' }}
        width="100%"
      >
        {video.map((vid) => (
          <source key={vid.uid} src={vid.url} type={vid.content_type} />
        ))}
      </video>
      <FlexLayout
        flexDirection="column"
        justifyContent="center"
        mx="auto"
        px={[4, 5, 0]}
        space={4}
        sx={{
          height: '100%',
          maxWidth: 'page-content-width',
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
        }}
      >
        <Sanitize
          html={header}
          sx={{
            h1: {
              variant: isMobile ? 'text.display-heading-l' : 'text.display-heading-xl',
            },
          }}
        />
      </FlexLayout>
    </Box>
  );
};
