import { Image } from './BelongGamesWidget';

export const filterGames = (games: Image[] | undefined, filter: string) => {
  let filteredData = games;

  if (filter === 'free-to-play') {
    filteredData = games?.filter((game) => !game.isGuestAccountAvailable);
  }

  if (filter === 'guest-access') {
    filteredData = games?.filter((game) => game.isGuestAccountAvailable);
  }

  return filteredData;
};
