import { GetArenas, GetPricesSummary } from '~/apis';

import { imageUrl } from './environment';
import { formatPriceObject } from './payment';

export interface UIArena {
  id: string;
  name: string;
  address: string;
  distance: number;
  city: string;
  notice?: string;
  phone: string;
  state: string;
  statusText: string;
  isBookingAllowed: boolean;
  heroImageUrl: string;
  logoImageUrl: string;
  slug: string;
  href: string;
  allowDetail: boolean;
  logoImageSmallUrl: string;
}

export function convertApiArenaToUIArena(arena: GetArenas.Arena): UIArena {
  const { name, id, location, contact, status, theme, infoText, slug, distanceFrom } = arena;
  const { city, state, address, postalCode } = location;
  const { heroImageUrl, logoImageUrl, logoImageSmallUrl } = theme;

  return {
    id,
    name,
    address,
    distance: Math.round(distanceFrom),
    city: `${city}, ${state} ${postalCode}`,
    notice: infoText,
    phone: contact.phone,
    state: `${city}, ${state}`,
    statusText: status?.text,
    isBookingAllowed: status?.allowBooking,
    heroImageUrl: imageUrl(heroImageUrl),
    logoImageUrl: imageUrl(logoImageUrl),
    logoImageSmallUrl: imageUrl(logoImageSmallUrl),
    slug,
    href: `/arenas/${slug}`,
    allowDetail: status.allowDetail,
  };
}

export function guidToPrettyPrintedCode(guid: string) {
  return guid?.split('-')?.[0]?.toUpperCase();
}

export function formatArenaPrice(price: GetPricesSummary.ProductItem) {
  const { unit: initialUnit, unitPrice, currency } = price;

  const unit = initialUnit.toLowerCase() === 'mo' ? 'month' : initialUnit;
  const formattedPrice = formatPriceObject({ amount: unitPrice, currency });

  if (unit === 'ea') {
    return formattedPrice;
  }

  return `${formattedPrice} / ${unit}`;
}
