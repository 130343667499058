import { AnchorButton, AnchorLinkButton } from '~/components/input';
import { Box, Image, Only, Sanitize, Text } from '~/ui';

interface TextImageCardProps {
  data: {
    content: string;
    image: { url: string };
    image_position: 'left' | 'right';
    has_image_dark_overlay?: boolean;
    title: string;
    cta?: {
      title: string;
      href: string;
    };
    cta_variant?: 'link' | 'button' | 'fullWidthButton';
  };
}

export const TextImageCard: React.FC<TextImageCardProps> = ({ data }) => {
  const {
    title,
    content,
    image_position: imagePosition,
    has_image_dark_overlay: hasImageDarkOverlay,
    cta,
    cta_variant: ctaVariant = 'link',
  } = data;

  const { url: imageUrl } = data?.image || {};

  function renderCta() {
    if (!cta?.href) {
      return null;
    }

    if (ctaVariant === 'link') {
      return <AnchorLinkButton href={cta.href} iconRight="chevronRight" text={cta.title} />;
    }

    if (ctaVariant === 'fullWidthButton') {
      return <AnchorButton href={cta.href} isFullWidth text={cta.title} />;
    }

    if (ctaVariant === 'button') {
      return <AnchorButton href={cta.href} text={cta.title} />;
    }
  }

  const isFlipped = imagePosition === 'left';
  return (
    <Box sx={{ position: 'relative' }}>
      <Only for="desktop">
        <Image
          src={imageUrl}
          sx={{
            maxWidth: '50%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            right: isFlipped ? 'initial' : 0,
            zIndex: hasImageDarkOverlay ? 0 : 1,
          }}
        />
      </Only>
      <Box
        sx={{
          background: [
            'black',
            'black',
            hasImageDarkOverlay
              ? `linear-gradient(${isFlipped ? '276deg' : '86.4deg'}, black 60%, transparent 80%)`
              : 'black',
          ],
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          ml={isFlipped ? ['initial', 'initial', 'auto'] : 'initial'}
          p={5}
          sx={{ maxWidth: ['100%', '100%', '640px'] }}
        >
          {title && (
            <Text as="h3" mb={5} variant="display-heading-m">
              {title}
            </Text>
          )}
          <Sanitize html={content} sx={{ p: { variant: 'text.display-paragraph-l' } }} />
          {renderCta()}
        </Box>
      </Box>
      <Only for="mobileAndTablet">
        <Box bg="black" p={5} pt={0} sx={{ textAlign: 'center' }}>
          <Image src={imageUrl} />
        </Box>
      </Only>
    </Box>
  );
};
