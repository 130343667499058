import { AnchorButton } from '~/components/input';
import { useMembershipStatus } from '~/hooks/membership';
import { Box, FlexLayout, Image, Only, Sanitize, Text } from '~/ui';

interface BelongLegendsWidgetProps {
  data: {
    title: string;
    body: string;
    image: { url: string };
    cta: {
      title: string;
      href: string;
    };
  };
}

const legendsLogoPath = '/assets/images/legends-logo.svg';

export const BelongLegendsWidget: React.FC<BelongLegendsWidgetProps> = ({ data }) => {
  const { isLoading, hasMembership } = useMembershipStatus();

  if (isLoading || hasMembership) {
    return null;
  }

  const { title, body, image, cta } = data;
  return (
    <Box sx={{ height: 'fit-content', position: 'relative', isolation: 'isolate' }}>
      <Image
        src={image?.url}
        sx={{
          display: 'block',
          position: ['relative', 'relative', 'absolute'],
          right: 0,
          height: ['240px', '240px', '100%'],
          width: ['100%', '100%', '45%'],
          objectFit: 'cover',
        }}
      />
      <Only for="mobile">
        <Image
          src={legendsLogoPath}
          sx={{ position: 'absolute', top: '40px', left: '24px', height: '160px', flexShrink: 0 }}
        />
      </Only>
      <FlexLayout
        px={[4, 5, 7]}
        py={[5, 5, 6]}
        space={[6, 6, 7]}
        sx={{
          position: 'relative',
          background: ['black', 'black', 'linear-gradient(86.4deg,black 60%,transparent 80%)'],
        }}
      >
        <Only for="tabletAndDesktop">
          <Image src={legendsLogoPath} sx={{ height: '160px', flexShrink: 0 }} />
        </Only>
        <Box sx={{ maxWidth: '640px' }}>
          <Text as="h2" variant="display-heading-m">
            {title}
          </Text>
          <Sanitize
            html={body}
            sx={{
              p: {
                variant: 'text.display-paragraph-l',
              },
            }}
          />
          {cta && <AnchorButton href={cta.href} text={cta.title} />}
        </Box>
      </FlexLayout>
    </Box>
  );
};
