import { AnchorButton } from '~/components/input';
import { Box, FlexLayout, Image, Only, Sanitize, Text, useScreenType } from '~/ui';

const CardItem = ({ text }: { text: string }) => {
  const { isMobile } = useScreenType();
  return (
    <Box bg="white-alpha-10" px={3} py={2} sx={{ borderRadius: 's', marginBottom: '16px !important' }}>
      <Text variant={isMobile ? 'compact-text-l' : 'compact-text-xl'}>{text}</Text>
    </Box>
  );
};

interface AboutCardProps {
  data: {
    title: string;
    body: string;
    items: string[];
    image: { url: string };
    cta: {
      href: string;
      title: string;
    };
  };
}

export const AboutCard: React.FC<AboutCardProps> = ({ data }) => {
  const { isMobile } = useScreenType();
  const { title, body, items, image, cta } = data;

  return (
    <Box sx={{ position: 'relative' }}>
      <Only for="tabletAndDesktop">
        {image && (
          <Image
            src={image.url}
            sx={{
              height: '100%',
              maxWidth: '65%',
              objectFit: 'cover',
              objectPosition: ['initial', '27% 50%'],
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
        )}
      </Only>
      <FlexLayout
        flexDirection="column"
        py={5}
        space={[7, 8]}
        sx={{
          background: `linear-gradient(90deg, #151515 40%, rgba(0, 0, 0, 0) 90%)`,
          position: 'relative',
        }}
      >
        <FlexLayout flexDirection="column" space={6} sx={{ maxWidth: isMobile ? 'auto' : '576px' }}>
          <FlexLayout flexDirection="column" space={[4, 6]}>
            <Text variant={isMobile ? 'display-heading-m' : 'display-heading-l'}>{title}</Text>
            <Sanitize
              html={body}
              sx={{
                color: 'white-alpha-75',
                p: {
                  margin: 0,
                  ':not(:last-child)': { marginBottom: 4 },
                  variant: isMobile ? 'text.display-paragraph-m' : 'text.display-paragraph-xl',
                },
              }}
            />
          </FlexLayout>
          <FlexLayout flexDirection="column" space={[0, 4]}>
            <FlexLayout alignItems="center" flexWrap="wrap" space={4} sx={{ maxWidth: isMobile ? 'auto' : '80%' }}>
              {items.map((item) => (
                <CardItem key={item} text={item} />
              ))}
            </FlexLayout>
            <AnchorButton href={cta.href} isFullWidth={isMobile} text={cta.title} variant="secondary" />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </Box>
  );
};
