import { AnchorButton } from '~/components/input';
import { arenasQuery } from '~/hooks/api';
import { ArenaWidget } from '~/pages-components/HomePage/ArenaWidget';
import { Box, FlexLayout, Text, useScreenType } from '~/ui';

interface ArenaWidgetsProps {
  data: {
    active: boolean;
  };
}

export const ArenaWidgets: React.FC<ArenaWidgetsProps> = ({ data }) => {
  const { isMobile, isTablet } = useScreenType();

  // Valid before the hook since "data" is baked-in during the build.
  if (!data?.active) {
    return null;
  }

  const { data: arenasData } = arenasQuery.use({});
  const arenas = arenasData?.items.slice(0, isTablet ? 2 : 3);

  return (
    <Box>
      <Text variant={isMobile ? 'display-heading-m' : 'display-heading-l'}>Arenas</Text>
      <Box
        mb={7}
        mt={5}
        sx={{ display: 'grid', gap: '32px', gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'] }}
      >
        {arenas?.map((arena) => (
          <ArenaWidget arena={arena} key={arena.id} />
        ))}
      </Box>
      <FlexLayout justifyContent="center">
        <AnchorButton href="/arenas" isFullWidth={isMobile} size="l" text="Discover More Arenas" variant="secondary" />
      </FlexLayout>
    </Box>
  );
};
