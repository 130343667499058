import { GetArena } from '~/apis';
import { withFormIntegration } from '~/hocs';
import { arenaQuery } from '~/hooks/api';
import { useTodayDate } from '~/hooks/date';
import { DatepickerWithFieldLabels } from '~/ui';
import { days, useDate } from '~/utils/date';

const FormDatepicker = withFormIntegration(DatepickerWithFieldLabels);

interface ArenaDatePickerProps {
  label: string;
  name: string;
  arenaSlug?: string;
  defaultValue?: Date | undefined;
  isDisabled?: boolean;
}

function getArenaClosedDays(arena: GetArena.Arena | undefined) {
  const closedDays = arena?.availability.hours.filter(({ start }) => start === 0);
  const closedDaysAsNumbers = closedDays?.map(({ day }) => days.indexOf(day));

  return {
    closedDays,
    closedDaysAsNumbers,
  };
}

export const ArenaDatePicker: React.FC<ArenaDatePickerProps> = ({
  defaultValue,
  label,
  name,
  arenaSlug,
  isDisabled,
}) => {
  const today = useTodayDate();
  const { data: arena } = arenaQuery.use(arenaSlug && { slug: arenaSlug });

  const allowedBookingDate = useDate(arena?.status.allowedBookingDate);
  const minDate = !allowedBookingDate || today > allowedBookingDate ? today : allowedBookingDate;

  const { closedDaysAsNumbers } = getArenaClosedDays(arena);

  return (
    <FormDatepicker
      data-testid="date-picker"
      defaultValue={defaultValue ?? minDate}
      disabledDays={closedDaysAsNumbers}
      format="LLL d, y"
      isDisabled={isDisabled}
      label={label}
      minDate={minDate}
      name={name}
    />
  );
};
