import { ComponentType } from 'react';

import { Link, LinkProps } from '~/components/shared';

interface WithHrefProps {
  href: string | undefined;
  target?: LinkProps['target'];
}

export function withHref<P>(Component: ComponentType<P>) {
  return (props: P & WithHrefProps) => {
    const { href, target } = props;

    if (href) {
      return (
        <Link href={href} target={target}>
          <Component {...props} />
        </Link>
      );
    }

    return <Component {...props} />;
  };
}
