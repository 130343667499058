import { Box, FlexLayout, Image, Only, Text, useScreenType } from '~/ui';
interface GameImageCardProps {
  data: {
    title: string;
    text: string;
    image: {
      url: string;
    };
  };
}

export const GameImageCard: React.FC<GameImageCardProps> = ({ data }) => {
  const { title, text, image } = data;
  const { isMobile, isTablet } = useScreenType();

  return (
    <Box backgroundColor="black" p={[4, 5]}>
      <FlexLayout alignItems={isMobile ? 'center' : 'flex-start'} flexDirection="row" mb={[5, 0]} space={[4, 6]}>
        <Image
          src={image.url}
          sx={{ width: ['80px', '190px'], minWidth: isTablet ? '190px' : 'auto', height: 'auto' }}
        />
        <FlexLayout flexDirection="column" space={5} sx={{ maxWidth: '858px' }}>
          <Text color="white" variant={isMobile ? 'compact-text-l' : 'title-l'}>
            {title}
          </Text>
          <Only for="tabletAndDesktop">
            <Text variant="display-paragraph-l">{text}</Text>
          </Only>
        </FlexLayout>
      </FlexLayout>
      <Only for="mobile">
        <Text variant="display-paragraph-m">{text}</Text>
      </Only>
    </Box>
  );
};
