import { AnchorButton } from '~/components/input';
import { Box, FlexLayout, Image, Only, Text, useScreenType } from '~/ui';

interface GamePassBannerProps {
  data: {
    title: string;
    text: string;
    background_image: {
      url: string;
    };
    logo: {
      url: string;
    };
    cta: {
      title: string;
      href: string;
    };
  };
}

export const GamePassBanner: React.FC<GamePassBannerProps> = ({ data }) => {
  const { title, cta, text, background_image, logo } = data;
  const { isMobile } = useScreenType();

  return (
    <Box sx={{ height: 'fit-content', position: 'relative', isolation: 'isolate' }}>
      <Only for="tabletAndDesktop">
        <Image
          src={background_image.url}
          sx={{
            display: 'block',
            position: 'absolute',
            right: 0,
            height: '100%',
            width: '45%',
            objectFit: 'cover',
          }}
        />
      </Only>
      <FlexLayout
        flexDirection={['column', 'row']}
        px={[4, 4]}
        py={[4, 5, 6]}
        space={[4, 7]}
        sx={{
          position: 'relative',
          background: [
            'black',
            'linear-gradient(86.4deg,black 80%,transparent 100%)',
            'linear-gradient(86.4deg,black 60%,transparent 80%)',
          ],
        }}
      >
        <Image src={logo.url} sx={{ maxWidth: ['170px', '132px', '193px'] }} />
        <FlexLayout flexDirection="column" space={5} sx={{ maxWidth: '679px' }}>
          <Text variant={isMobile ? 'compact-text-xl' : 'display-heading-m'}>{title}</Text>
          <Text variant="display-paragraph-l">{text}</Text>
          <AnchorButton href={cta.href} text={cta.title} />
        </FlexLayout>
      </FlexLayout>
      <Only for="mobile">
        <Box bg="black">
          <Image
            src={background_image.url}
            sx={{
              display: 'block',
              position: 'relative',
              right: 0,
              height: '200px',
              width: '100%',
              objectFit: 'cover',
              maskImage: 'linear-gradient(270deg, black 50%, transparent 80%)',
            }}
          />
        </Box>
      </Only>
    </Box>
  );
};
