import { TextIconBox } from '~/components/shared';
import { Divider, FlexLayout, Text } from '~/ui';

interface Filters {
  value: string;
  onChange(value: string): void;
}

export const Filters: React.FC<Filters> = ({ value, onChange }) => {
  return (
    <FlexLayout flexDirection="row" space={3} sx={{ alignSelf: ['start', 'start', 'center'], minWidth: '300px' }}>
      <Text color={value === 'all' ? 'pink-500' : 'white-alpha-50'} variant="label-m" onClick={() => onChange('all')}>
        All
      </Text>
      <Divider color="white-alpha-10" orientation="vertical" />
      <TextIconBox
        color={value === 'free-to-play' ? 'pink-500' : 'white-alpha-50'}
        icon="free"
        text="Free to play"
        variant="label-s"
        onClick={() => onChange('free-to-play')}
      />
      <Divider color="white-alpha-10" orientation="vertical" />
      <TextIconBox
        color={value === 'guest-access' ? 'pink-500' : 'white-alpha-50'}
        icon="access"
        text="Guest access"
        variant="label-s"
        onClick={() => onChange('guest-access')}
      />
    </FlexLayout>
  );
};
