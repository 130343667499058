import { GetArena } from '~/apis/arenas/getArena';
import { Form } from '~/components/form';
import { ArenaDatePicker } from '~/components/shared/ArenaDatePicker';
import { ArenaWorkingHoursSelector } from '~/components/shared/ArenaWorkingHoursSelector';
import { getFeatureFlags } from '~/services/featureFlags';
import { Button, GridLayout, useScreenType } from '~/ui';

import { ArenaSelect } from './ArenaSelect';
import { useBookNowHandler } from './hooks';

interface QuickBookFormProps {
  preselectedArena?: GetArena.Arena;
  displayTimeSelectors?: boolean;
}

export const QuickBookForm: React.FC<QuickBookFormProps> = ({
  preselectedArena,
  displayTimeSelectors: initialDisplayTimeSelectors = true,
}) => {
  const { 'arena-pass': arenaPass } = getFeatureFlags();
  const { isMobile } = useScreenType();
  const { bookNowHandler, mobileBookNowHandler, isLoading } = useBookNowHandler();

  const displayTimeSelectors = !arenaPass && ((!arenaPass && initialDisplayTimeSelectors) || preselectedArena);

  if (isMobile && preselectedArena) {
    return (
      <Button
        data-testid="book-now-mobile"
        isFullWidth
        isLoading={isLoading}
        size="l"
        text="BOOK NOW"
        variant="brand"
        onClick={() => mobileBookNowHandler(preselectedArena.slug)}
      />
    );
  }

  return (
    <Form
      initialValues={{ arenaSlug: preselectedArena?.slug }}
      render={({ handleSubmit, values }) => (
        <GridLayout
          alignItems="flex-end"
          gap={5}
          gridTemplateColumns={
            preselectedArena
              ? ['1fr', '1fr', displayTimeSelectors ? 'auto 1fr' : 'auto']
              : ['1fr', '1fr', `repeat(${displayTimeSelectors ? '2' : '1'}, auto)`]
          }
        >
          <GridLayout
            alignItems="flex-end"
            gap={5}
            gridTemplateColumns={preselectedArena ? ['none', 'repeat(2, 1fr)'] : ['none', 'repeat(3, 1fr)']}
          >
            {!preselectedArena && <ArenaSelect name="arenaSlug" />}
            <ArenaDatePicker arenaSlug={values.arenaSlug} label="Date" name="date" />
            {!displayTimeSelectors && (
              <Button
                data-testid="book-now"
                isDisabled={!values.arenaSlug}
                isFullWidth
                isLoading={isLoading}
                size="l"
                text="BOOK NOW"
                variant={preselectedArena ? 'brand' : 'primary'}
                onClick={handleSubmit}
              />
            )}
          </GridLayout>
          {displayTimeSelectors && (
            <GridLayout
              alignItems="flex-end"
              gap={5}
              gridTemplateColumns={['none', 'repeat(2, 1fr) auto', 'repeat(2, 1fr) auto']}
            >
              <ArenaWorkingHoursSelector
                arenaSlug={values.arenaSlug}
                data-testid="pick-start-time"
                label="Start time"
                name="startTime"
                type="start"
              />
              <ArenaWorkingHoursSelector
                arenaSlug={values.arenaSlug}
                data-testid="pick-end-time"
                label="End time"
                name="endTime"
                type="end"
              />
              <Button
                data-testid="book-now"
                isDisabled={!values.arenaSlug}
                isFullWidth
                isLoading={isLoading}
                size="l"
                text="BOOK NOW"
                variant={preselectedArena ? 'brand' : 'primary'}
                onClick={handleSubmit}
              />
            </GridLayout>
          )}
        </GridLayout>
      )}
      onSubmit={bookNowHandler}
    />
  );
};
