import { AnchorButton } from '~/components/input';
import blackFridayBg from '~/public/assets/images/black-friday-background.png';
import { Box, FlexLayout, Image, Only, Sanitize, Text } from '~/ui';

interface BlackFridayBannerProps {
  data: {
    heading: string;
    body: string;
    image: { url: string };
    cta: {
      title: string;
      href: string;
    };
    active: boolean;
    reversed: boolean;
  };
}

export const BlackFridayBanner: React.FC<BlackFridayBannerProps> = ({ data }) => {
  if (!data?.active) {
    return null;
  }

  const { heading, body, image, cta, reversed } = data;
  return (
    <Box p={[4, 5]} sx={{ backgroundImage: `url(${blackFridayBg.src})` }}>
      <Only for="mobile">
        <Box mb={5} mx="auto" sx={{ width: 'fit-content' }}>
          <Image src={image.url} sx={{ height: '192px', width: '192px' }} />
        </Box>
      </Only>
      <FlexLayout
        alignItems="center"
        flexDirection={reversed ? 'row-reverse' : 'row'}
        mx="auto"
        space={5}
        sx={{ width: 'fit-content' }}
      >
        <Only for="tabletAndDesktop">
          <Box sx={{ height: '100%', flexShrink: 0 }}>
            <Image src={image.url} sx={{ height: '192px', width: '192px' }} />
          </Box>
        </Only>
        <FlexLayout flexDirection="column" space={4} sx={{ maxWidth: '560px' }}>
          <Box>
            <Text as="h2" variant="display-heading-m">
              {heading}
            </Text>
            <Sanitize
              html={body}
              sx={{
                p: {
                  color: 'white-alpha-75',
                  margin: 0,
                  variant: 'text.display-paragraph-l',
                },
              }}
            />
          </Box>
          {cta && <AnchorButton href={cta.href} text={cta.title} />}
        </FlexLayout>
      </FlexLayout>
    </Box>
  );
};
