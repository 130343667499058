import { QuickBookBar } from '~/components/QuickBookBar';
import { SignupBanner, SponsorsContainer } from '~/components/shared';
import { AboutCard } from '~/pages-components/HomePage/AboutCard';
import { ArenaWidgets } from '~/pages-components/HomePage/ArenaWidgets';
import { HomeHero } from '~/pages-components/HomePage/HomeHero';
import { WhatsNew } from '~/pages-components/HomePage/WhatsNew';

import { Seo } from '../Seo';
import { BelongGamesWidget } from './BelongGamesWidget';
import { BelongLegendsWidget } from './BelongLegendsWidget';
import { BelongShoppingWidget } from './BelongShoppingWidget';
import { BlackFridayBanner } from './BlackFridayBanner';
import { CareersWidget } from './CareersWidget';
import { CovidBanner } from './CovidBanner';
import { GameImageCard } from './GameImageCard';
import { GamePassBanner } from './GamePassBanner';
import { Hero } from './Hero';
import { ListImageCard } from './ListImageCard';
import { MobilePromotionWidget } from './MobilePromotionWidget';
import { TextImageCard } from './TextImageCard';

// Bind components to content stack data types names
export const seo = Seo;
export const hero_header = Hero;
export const text_image_card = TextImageCard;
export const list_image_card = ListImageCard;
export const game_image_card = GameImageCard;
export const sponsors_banner = SponsorsContainer;

export const signup_banner = SignupBanner;
export const mobile_promotion_banner = MobilePromotionWidget;
export const belong_legends_widget = BelongLegendsWidget; // TODO - separate Home page, Account and Arena Legends banners
export const game_pass_banner = GamePassBanner;
export const belong_games_widget = BelongGamesWidget;
export const belong_shopping_widget = BelongShoppingWidget;

// HomePage specific items
export const home_page_hero = HomeHero;
export const booking_bar_placeholder = QuickBookBar;
export const covid_banner = CovidBanner;
export const arenas_widgets_placeholder = ArenaWidgets;
export const featured_news_placeholder = WhatsNew;
export const careers_banner = CareersWidget;
export const belong_benefits = AboutCard;
export const black_friday_banner = BlackFridayBanner;
