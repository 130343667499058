import { FieldValidator } from 'final-form';
import { ComponentType } from 'react';
import { useField } from 'react-final-form';

interface WithFormIntegrationRequiredProps {
  value?: any;
  onChange?(value: any): void;
  error?: string;
}

export function withFormIntegration<T extends WithFormIntegrationRequiredProps>(Comp: ComponentType<T>) {
  type ResultProps = Omit<T, 'value' | 'onChange' | 'error'> & {
    validate?: FieldValidator<any>;
    name: string;
    defaultValue?: any;
    initialValue?: any;
  };

  return (props: ResultProps) => {
    const { name, validate, defaultValue, initialValue, ...rest } = props;
    const {
      input: { value, onChange, onBlur, onFocus },
      meta,
    } = useField(name, { validate, defaultValue, initialValue });

    const error = meta.touched ? meta.error || meta.submitError : undefined;
    const UntypedComponent = Comp as any;

    return (
      <UntypedComponent
        {...rest}
        error={error}
        value={value}
        onBlur={onBlur}
        onChange={(value) => onChange({ target: { value } })}
        onFocus={onFocus}
      />
    );
  };
}
