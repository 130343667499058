import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { GetArena } from '~/apis';
import { TimeSelect } from '~/components/input';
import { getTimeOptions } from '~/components/shared/ArenaWorkingHoursSelector/arena-time-utils';
import { defaultTimeSlot } from '~/components/shared/ArenaWorkingHoursSelector/utils';
import { withFormIntegration } from '~/hocs';
import { useCurrentHours } from '~/hooks/date';
import { showToast, withFieldLabels } from '~/ui';
import { addDaysToDate, formatDate } from '~/utils/date';

const TimeSelectWithFieldLabels = withFieldLabels(TimeSelect);
const FormTimeSelect = withFormIntegration(TimeSelectWithFieldLabels);

interface ArenaWorkingHoursSelectorProps {
  type: 'start' | 'end';
  arena: GetArena.Arena;
  name: string;
  label: string;
  defaultValue?: string;
  isDisabled?: boolean;
  'data-testid'?: string;
}

export const ArenaWorkingHoursSelector: React.FC<ArenaWorkingHoursSelectorProps> = ({
  arena,
  defaultValue: initialDefaultValue,
  type,
  name,
  label,
  isDisabled,
  'data-testid': dataTestId,
}) => {
  const currentHours = useCurrentHours(arena.availability.tz);
  const { values } = useFormState();
  const { change } = useForm();
  const { date, startTime } = values;

  const timeOptions = getTimeOptions(arena, date, type, currentHours, startTime);

  // default value time is the first available time slot
  const defaultValue = initialDefaultValue ?? defaultTimeSlot(type, startTime, timeOptions);

  const currentOptionExists = timeOptions.some((option) => option.value === values[name]);

  useEffect(() => {
    if (currentOptionExists || values[name] === undefined) {
      return;
    }
    if (timeOptions.length > 0) {
      // if selected option is no longer available, use first available option instead
      change(name, timeOptions[0].value);
      return;
    }

    if (type === 'end') {
      return;
    }

    // if there are no more time slots today,
    // offer slots for tomorrow
    const tomorrow = addDaysToDate(1, date);
    showToast({
      variant: 'warning',
      text: `Arena is closing soon. We have changed your selected date to ${formatDate(tomorrow, 'MMM dd, yyyy')}`,
      autoClose: 8000,
    });
    change('date', tomorrow);
  }, [date, timeOptions.length]);

  return (
    <FormTimeSelect
      // change to default values updates the current state also
      // we'll use the default value to update the current value
      // ONLY IF the currently selected option is no longer present
      // in the options list
      data-testid={dataTestId}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      label={label}
      name={name}
      timeOptions={timeOptions}
    />
  );
};
