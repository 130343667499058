import { Link } from '~/components/shared';
import { Box, FlexLayout, Image, LinkButton, Sanitize, Text } from '~/ui';

interface ImageData {
  url: string;
}

interface CareersWidgetProps {
  data: {
    title: string;
    supertitle: string;
    body: string;
    cta: {
      title: string;
      href: string;
    };
    image_left: ImageData;
    image_right: ImageData;
  };
}

export const CareersWidget: React.FC<CareersWidgetProps> = ({ data }) => {
  const { title, supertitle, body, cta, image_left: imageLeft, image_right: imageRight } = data;
  return (
    <FlexLayout bg="black">
      <Box sx={{ display: ['none', 'block'], position: 'relative', flexGrow: 1, flexShrink: 1000 }}>
        <Image src={imageLeft.url} sx={{ position: 'absolute', objectFit: 'cover', width: '100%', height: '100%' }} />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: `linear-gradient(90deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 1) 100%)`,
          }}
        />
      </Box>
      <Box p={5} sx={{ flexBasis: '425px' }}>
        <Text variant="label-s">{supertitle}</Text>
        <Text as="h5" mb={3} mt={2} variant="title-l">
          {title}
        </Text>
        <Sanitize
          html={body}
          sx={{
            p: { variant: 'text.text-m' },
          }}
        />
        <Link href={cta.href} target="_blank">
          <LinkButton iconRight="externalLink" text={cta.title} />
        </Link>
      </Box>
      <Box sx={{ display: ['none', 'block'], position: 'relative', flexGrow: 1, flexShrink: 1000 }}>
        <Image src={imageRight.url} sx={{ position: 'absolute', objectFit: 'cover', width: '100%', height: '100%' }} />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: `linear-gradient(270deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 1) 100%)`,
          }}
        />
      </Box>
    </FlexLayout>
  );
};
