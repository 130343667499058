import format from 'date-fns/format';
import { useState } from 'react';

import { useAuthenticatedUser } from '~/hooks/api';
import { useStatefulRouter } from '~/hooks/statefulRouter';

interface BookNowHandlerArgs {
  playersNo: number;
  date: Date;
  startTime: string;
  endTime: string;
  arenaSlug: string;
}

export function useBookNowHandler() {
  const { push, pushWithState } = useStatefulRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthenticatedUser();

  function mobileBookNowHandler(arenaSlug: string) {
    setIsLoading(true);

    const data = {
      pathname: `/arenas/${arenaSlug}/book`,
    };

    if (user) {
      return push(data);
    }

    return pushWithState('/login', { afterLoginRedirectPath: data });
  }

  function bookNowHandler({ playersNo, date, startTime, endTime, arenaSlug }: BookNowHandlerArgs) {
    setIsLoading(true);

    const data = {
      pathname: `/arenas/${arenaSlug}/book`,
      query: { players: playersNo, date: format(date, 'yyyy-MM-dd'), startTime, endTime },
    };

    if (user) {
      return push(data);
    }

    return pushWithState('/login', { afterLoginRedirectPath: data });
  }

  return { mobileBookNowHandler, bookNowHandler, isLoading };
}
